/* navbar-css */
.btn-primary {
  background: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
}

.Navbar-position {
  margin-top: 110px;
}

.vdmarginHomePage {
  margin-top: -112px !important;
}
@media (max-width: 992px) {
  .vdmarginHomePage {
    margin-top: 0px !important;
  }
  .Navbar-position {
    margin-top: 17px;
  }
  .navbar-collapse {
    max-height: 0px;
    transition: max-height 0.6s ease-in-out;
    overflow: hidden;
  }

  .navbar-collapse.open {
    max-height: 500px;
  }
}
#navbar {
  background: white;
}
.navLogo :hover {
  cursor: pointer !important;
}

/* Footer css  */
.footerButton {
  color: #fff !important;
  border: 2px solid #fff !important;
  border-radius: 0px !important;
}
.footerButton:hover {
  background-color: var(--primary-color) !important;
}

a {
  text-decoration: none !important;
}

/* AboutUs Section  */
.aboutusfirstsection {
  margin-top: -35px !important;
}
.aboutusSecondSection {
  margin-top: -35px;
}
@media (max-width: 992px) {
  .aboutusSecondSection {
    margin-top: -90px;
  }
}

/* Blog Section  */
.card {
  border: none !important;
}

.blogSection1 {
  margin-top: -39px;
}
@media (max-width: 992px) {
  .blogSection2 {
    margin-top: -70px;
  }
}
/* Blog-1 css part for carousel  */
.carousel {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}
.blog-2crousel {
  position: relative;
  width: 100%;
  height: 531px;
  overflow: hidden;
}

.carousel-images {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.carousel-image {
  flex: 1 0 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

.carousel-image.active {
  opacity: 1;
  display: block;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.carousel-buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.carousel-buttons button {
  background-color: white;
  border: 1px red;
  opacity: 0.7;
  outline: none;
  cursor: pointer;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.carousel-buttons button:hover {
  opacity: 0.9;
}
.carousel-buttons button:hover {
  color: #ffc107;
}

.prev {
  background: #ffffffa6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: var(--black);
  transition: background 0.2s ease;
}
.prev:after {
  content: "\2190";
  font: var(--fa-font-regular);
  font-weight: 700;
  color: black;
  margin-top: -3px;
  position: absolute;
  top: 11px;
  left: 27px;
  width: 6px;
  height: 103px;
  font-size: 24px;
}
.next {
  background: #ffffffa6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: var(--black);
  transition: background 0.2s ease;
}
.next::after {
  content: "\2192";
  font: var(--fa-font-regular);
  font-weight: 700;
  color: black;
  margin-top: -3px;
  position: absolute;
  top: 11px;
  right: 47px;
  width: 6px;
  height: 103px;
  font-size: 24px;
}
@media (max-width: 992px) {
  .carousel-images {
    left: -101px;
  }
}
@media (max-width: 769px) {
  .carousel-images {
    left: -199px;
  }
  .carousel {
    height: 270px;
  }
  .blog-2crousel {
    height: 423px;
  }
}

.blog1Section-1 {
  margin-top: -110px;
}
.headingblog1colorchanging {
  color: var(--primary-color) !important;
}
.cardborderblog1 {
  border: 1px solid #dbd1d1;
}
@media (max-width: 992px) {
  .blog1Section-1 {
    margin-top: -49px;
  }
}

/* Home page css   */
.text-primary-col {
  color: var(--primary-color) !important;
}
input[type="range"]::-webkit-slider-thumb {
  background-color: var(--primary-color) !important;
}
.Whytogosolar-Formbutton {
  border: 2px solid var(--primary-color) !important;
}
.Whytogosolar-Formbutton:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.homepageFormButton {
  width: 100% !important;
}
.HomeBlog {
  margin-top: 20px;
}
.homeBlogButton {
  margin-bottom: 10px;
  border: 2px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
}
.homeBlogButton:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}
a.contacthov :hover{
  color: var(--primary-color) !important;
}
/* Form Css */
.locationIconSize {
  font-size: 22px;
}
.value-container svg {
  font-size: 33px;
  margin-top: -5px;
  font-weight: 900;
}
.companyBtn {
  border-radius: 25px !important;
  font-size: 18px !important;
}
.companyBtn:hover {
  background-color: var(--primary-color-btn-hover) !important;
  border-color: var(--primary-color-btn-hover) !important;
  color: #dadada !important;
}

.alignRight {
  display: flex;
  justify-content: flex-end;
  margin-top: -21px;
}
.btn-share:hover {
  transform: scale(1.1);
  color: var(--primary-color);
}
.btn-share-container{
  font-size: 16px;
}
.formfbButton{
  margin-right: 40px !important;
}
.btn-whatsapp{
  color: #25d366 !important;
  margin-right: 22px;
  display: none;
}

.btn-whatsapp:hover{
  transform: scale(1.1)!important;
  color: var(--primary-color) !important;
}
@media (max-width: 1280px) {
  .btn-whatsapp{
    
    display: inline-block;
  }
}
.twoHunderedLek:hover{
  cursor: pointer !important;
}