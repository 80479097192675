@import url("https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quantico:wght@400;700&display=swap");
@font-face {
  font-family: "Evanston Tavern 1858";
  src: url("../fonts/EvanstonTavern-1858Light.woff") format("woff");
}

:root {
  /*--navy-color: #2F4858;*/
  --navy-color: #315877;
  --primary-color: #f2a000;
  --primary-color-btn-hover: #d68e00;
  --primary-color-rgb: rgb(242, 160, 0);
  --nav-link-color: #343f52;
  --headings-color: #4f4f51;
  --black: #1c1c1c;
  --bg-primary-gradient: linear-gradient(
    to top,
    #fff9e4cc,
    #e2d2b3b5,
    #d8aeaf69
  );
  --bg-secondary-gradient: linear-gradient(
    to top,
    #a288d3a2,
    #2ebf919d,
    #0cbdbdb7
  );
}

::selection {
  background: rgba(242, 160, 0, 0.7);
  color: #fff;
}

.nav-link.btn-primary {
  transition: all 0.2s ease;
}

.nav-logo p.logo-content {
  font-size: 10px;
  font-family: "Quantico", sans-serif;
  font-weight: 700;
}

.nav-logo p.logo-content.small-p {
  font-size: 8px;
}

.btn.btn-primary,
.btn.btn-primary-transparent {
  padding: 10px 20px;
}

.btn-primary:hover,
.btn-primary:active {
  background: var(--primary-color-btn-hover) !important;
  border: 2px solid var(--primary-color) !important;
}

.btn-primary-transparent {
  background: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.btn-primary-transparent:hover,
.btn-primary-transparent:active {
  background: var(--primary-color);
  border: 2px solid var(--primary-color) !important;
  color: #fff;
}

.btn-color-white {
  color: #fff;
  border: 2px solid #fff;
}

.btn-square {
  border-radius: 0;
}

/* .btn-primary-over-colored-background {
} */
.btn-primary-over-colored-background:hover {
  background: transparent !important;
  color: var(--primary-color);
}
/*svg {*/
/*color: var(--primary-color);*/
/*}*/

/*.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {*/
/*color: var(--heading-color) !important;*/
/*}*/

.bg-navy {
  background: var(--navy-color);
  color: #fff;
}

.text-primary {
  color: var(--primary-color) !important;
}

.blog-nav .nav-link {
  color: var(--nav-link-color) !important;
}
.blog-nav .nav-link:hover {
  color: var(--primary-color) !important;
}
.blog-nav .nav-link.btn:hover {
  color: var(--black) !important;
}

nav .nav-link {
  color: var(--nav-link-color) !important;
}
nav .nav-link:hover {
  color: var(--primary-color) !important;
}
nav .nav-link.btn:hover {
  color: var(--black) !important;
}

.home nav .nav-logo .logo-content {
  color: #f8f9fa;
}

.home nav .nav-link {
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.home nav .nav-link:hover {
  color: var(--primary-color-btn-hover) !important;
}

.home nav .nav-link.btn-primary:hover {
  color: var(--black) !important;
}

.home nav.bg-light a,
nav.bg-light .nav-logo .logo-content {
  color: var(--black) !important;
}

a {
  text-decoration: none;
  transition: color 0.3s ease;
}

.fixed-width {
  max-width: 1150px;
}

.video-background {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -100;
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}

.video-overlay:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -99;
}

#navbar {
  transition: 0.5s;
  padding-bottom: 0 !important;
}

#navbar-sm {
  margin-top: -4px;
  padding-bottom: 10px;
}

.showcase-card {
  min-height: 220px;
  background: rgba(255, 255, 255, 0.8);
  flex-direction: row;
  align-items: center;
}

.col-md-8.showcase-content {
  margin-top: 70px;
}

/*.btn-primary{*/
/*text-align: center;*/
/*background: var(--primary-color);*/
/*border-color: var(--primary-color);*/
/*}*/

/*.btn-primary:hover, .nav-link.btn-primary:active{*/
/*background: var(--bs-btn-active-color);*/
/*box-shadow: 0 0.25rem 0.75rem rgba(205, 208, 212, 0.3);*/
/*}*/

/*:not(.btn-check)+.btn:active {*/
/*color: var(--bs-btn-active-color);*/
/*background-color: var(--primary-color);*/
/*border-color: var(--bs-btn-active-border-color);*/
/*}*/

/*.quote-btn{*/
/*border: 2px solid var(--primary-color);*/
/*background: var(--primary-color);*/
/*color: #fff;*/
/*padding: 10px 20px;*/
/*letter-spacing: 1px;*/
/*transition: all 0.5s ease;*/
/*}*/

/*.quote-btn:hover{*/
/*background: transparent;*/
/*color: var(--primary-color);*/
/*}*/

/*.quote-btn-outline{*/
/*background: transparent;*/
/*color: var(--primary-color);*/
/*}*/

/*.quote-btn-outline:hover{*/
/*background: var(--primary-color);*/
/*color: #fff;*/
/*}*/

/*.btn-primary{*/
/*text-align: center;*/
/*background: var(--primary-color);*/
/*border-color: var(--primary-color);*/
/*}*/

/*.btn-primary:hover, .nav-link.btn-primary:active{*/
/*background: var(--bs-btn-active-color);*/
/*box-shadow: 0 0.25rem 0.75rem rgba(205, 208, 212, 0.3);*/
/*}*/

/*:not(.btn-check)+.btn:active {*/
/*color: var(--bs-btn-active-color);*/
/*background-color: var(--primary-color);*/
/*border-color: var(--bs-btn-active-border-color);*/
/*}*/

/*.quote-btn{*/
/*border: 2px solid var(--primary-color);*/
/*background: var(--primary-color);*/
/*color: #fff;*/
/*padding: 10px 20px;*/
/*letter-spacing: 1px;*/
/*transition: all 0.5s ease;*/
/*}*/

/*.quote-btn:hover{*/
/*background: transparent;*/
/*color: var(--primary-color);*/
/*}*/

/*.quote-btn-outline{*/
/*background: transparent;*/
/*color: var(--primary-color);*/
/*}*/

/*.quote-btn-outline:hover{*/
/*background: var(--primary-color);*/
/*color: #fff;*/
/*}*/

.formContainer {
  /*background: rgba(47,72,88,0.9);*/
  background: var(--navy-color);
  border: solid 1px var(--primary-color);
  /*box-shadow: 0 0 20px 0 rgba(0,51,102,.25);*/
  /*-moz-box-shadow: 0 0 20px 0 rgba(0,51,102,.25);*/
  /*-webkit-box-shadow: 0 0 20px 0 rgba(0,51,102,.25);*/
  border-radius: 3px;
  padding: 30px 22px;
  margin-bottom: 30px;
  text-align: center;
}

/*.nav-item .nav-link:hover{*/
/*color: var(--primary-color) !important;*/
/*}*/

/*.nav-link.btn-primary:hover{*/
/*color:  var(--black) !important;*/
/*transform: translateY(-0.15rem);*/
/*}*/

.custom-range::-webkit-slider-thumb {
  background-color: var(--primary-color);
}

.flex-item {
  width: 32%;
  height: 400px;
  overflow: hidden;
}

.flex-item img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-position: center;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.flex-item img.positioned-image {
  object-position: 70%;
}

.flex-item.position-relative .overlay {
  cursor: default;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: opacity 0.7s;
}

.box-content {
  transform: translateY(-10px);
  transition: transform 0.4s ease;
  padding: 0 20px;
}

.flex-item:hover img {
  transform: scale(1.05);
}

.blog-card-img {
  overflow: hidden;
}

.blog-card-img:hover img {
  transform: scale(1.1);
}

.flex-item:hover .overlay,
.blog-card-img:hover .overlay {
  opacity: 1;
}

.flex-item:hover .box-content,
.blog-card-img:hover .box-content {
  transform: translateY(0);
}

.steps-btn-container .quote-btn {
  padding: 10px 30px;
}

.steps-heading {
  font-size: 34px;
}

/* .blog-card-height{
  height: 360px;
} */

.blog-card {
  /* padding: 10px; */
  height: 360px;
  background: #fff;
  border: none;
  border-radius: 0;
}

.blog-img {
  height: 198px;
  width: 100%;
  object-fit: cover;
  border-radius: 0;
  transition: transform 0.8s ease;
}

.blog-card-title {
  transition: color 0.15s ease;
}

.blog-card-title:hover {
  color: var(--primary-color);
}

.blog-card-wrapper {
  margin-top: -10rem;
}

.blog-card-wrapper .card,
.img-container {
  box-shadow: 0 0 0 0.05rem rgb(8 60 130 / 6%), 0 0 1.25rem rgb(30 34 40 / 4%);
  border: 1px solid rgba(164, 174, 198, 0.2);
  border-radius: 10px;
}

.img-container img {
  border-radius: 5px;
}

.blog-card-wrapper .card .card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.blog-card-wrapper .card .card-slider .slide-content {
  padding: 0;
}

.slide-content {
  /* margin: 0 40px; */
  overflow: hidden;
  /*padding: 20px 10px;*/
}

.swiper-pagination-bullet {
  background-color: var(--primary-color);
}

/* .bg-blur-primary{
  background: var(--bg-primary-gradient);
} */

/* .blog-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
} */

.caveat-text {
  font-size: 31px;
  font-family: "Caveat Brush", cursive;
}

.fw-500 {
  font-weight: 500 !important;
}

.img-fluid.showcase-card-icon {
  width: 60px;
  height: 60px;
}

.home-form {
  max-width: 885px;
}

.home-form input,
.home-form textarea {
  margin-bottom: 10px;
  border-radius: 0;
}

.home-form button {
  border-radius: 0;
}

.home-form .form-control:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(242, 160, 0, 0.85) !important;
}

.contact-us-privacy {
  font-size: 14px;
  display: flex;
}

.bg-footer {
  background: var(--black);
}

.lead-footer {
  font-size: 18px;
}

.footer-info p {
  margin-bottom: 5px;
}

.footer-info a:hover {
  color: var(--primary-color) !important;
}

.footer-info h3 {
  font-size: 22px;
}

.get-in-touch h3 {
  font-size: 25px;
}

.get-in-touch p {
  margin-bottom: 0;
}

.footer-btn {
  border: 1px solid #f8f9fa;
  background: transparent;
  color: #f8f9fa;
}

.footer-btn:hover {
  border-color: var(--primary-color);
}

.go-to-top-btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  /* display: none; */
  opacity: 0;
  color: var(--primary-color);
  font-size: 30px;
  transition: all 0.2s ease;
  z-index: 10;
}

.go-to-top-btn:hover {
  transform: scale(1.2);
}

/*.blog-nav .nav-link{*/
/*color: var(--nav-link-color) !important;*/
/*}*/
/*.blog-nav .nav-link:hover{*/
/*color: var(--primary-color) !important;*/
/*}*/
/*.blog-nav .nav-link.btn:hover{*/
/*color: var(--black) !important;*/
/*}*/

.swiper-button-next,
.swiper-button-prev {
  background: #ffffffa6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: var(--black);
  transition: background 0.2s ease;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: #ffffffda;
}

.swiper-button-next:after {
  content: "→";
  font: var(--fa-font-regular);
  font-weight: bold;
}

.swiper-button-prev:after {
  content: "←";
  font: var(--fa-font-regular);
  font-weight: bold;
}

.blog-title-container {
  padding: 120px 15px 30px !important;
}
.blog-page-content.blog-title-container {
  padding: 120px 15px 0px !important;
}
.about-us-container {
  padding: 120px 15px 10px !important;
}
.about-best-solar {
  padding-top: 5rem;
}
.acc-link:hover {
  color: var(--primary-color) !important;
}
/* Solar Panels */
.solar-panels-img-container {
  background: url("../img/old/what-are-solar-panels.png") no-repeat center
    center/cover;
  margin-top: 6rem;
}

.solar-panels-container {
  padding: 100px 15px;
}

.w-20 {
  width: 20%;
}
.w-80 {
  width: 80%;
}
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}

.solar-page-info {
  border-right: 1px solid #cccccc;
}

.border-right-0 {
  border-right: 0;
}

.solar-page-info .lead {
  color: #202020;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}

.go-back {
  transition: all 0.2s ease-in-out;
}

.go-back:hover {
  cursor: pointer;
  color: var(--primary-color) !important;
  transform: scale(1.07);
}

.formBox .infoBox2 {
  padding: 20px 17px 30px;
}

.city-result {
  background: #f8f9fa;
  position: absolute;
  width: 100%;
  z-index: 100;
  border: 2px solid #c1c1c1;
  border-radius: 7px;
  padding: 5px;
  top: 40px;
  /* height: 180px; */
  overflow: auto;
}

.city-result ul {
  list-style: none;
  padding: 0 5px;
  margin: 0;
}

.city-result ul li {
  font-size: 18px;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 43px;
  text-align: left;
  font-family: "PT Serif", serif;
  font-weight: 400;
  padding-left: 15px;
}

.city-result ul li:hover {
  background: #dadada;
  cursor: pointer;
}

.city-result ul li i {
  padding-right: 7px;
}

.form-floating.textarea {
  height: 180px;
}

.form-floating.textarea textarea {
  height: 100%;
}

.companyBtn {
  border-radius: 25px;
  font-size: 18px;
}

.companyBtnSelected,
.companyBtn:hover {
  background-color: var(--primary-color-btn-hover);
  border-color: var(--primary-color-btn-hover);
  /* box-shadow: 0 3px 7px 3px #FFF; */
  color: #dadada !important;
}

.companyBtnNotSelected {
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

#boxPage-6 form .form-control {
  padding: 0.5rem 0.75rem;
}

.border-red {
  border: 2px solid red;
}

#pageProgress {
  width: 100%;
  background-color: var(--navy-color);
  border-radius: 3px;
  margin-bottom: 5px;
}

#progressBar {
  width: 1%;
  height: 10px;
  background-color: var(--primary-color);
  border-radius: 3px;
}

/* Map */
#map,
#map-2 {
  height: 280px;
  width: 100%;
}

/* Google Autocomplete Container */
.pac-container {
  z-index: 10000;
}

.blog-body {
  padding-top: 20px;
}

/* Circular progress */

.circular-progress {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: var(--primary-color);
}
.circular-progress:before {
  content: "";
  position: absolute;
  height: 84%;
  width: 84%;
  background-color: var(--navy-color);
  border-radius: 50%;
}
.value-container {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  color: var(--primary-color);
  font-size: 26px;
}

.msgPopup {
  /*position: fixed;*/
  background-color: rgb(0 0 0 / 50%);
  width: 100vw;
  height: 100vh;
  z-index: 1031;
  display: none;
}

.msgPopup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 200px;
  color: #fff;
  background-color: var(--navy-color);
  border-radius: 5px;
  text-align: center;
  border: 2px solid var(--primary-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 1540px) {
  #map,
  #map-2 {
    height: 200px;
  }
}

@media (max-width: 1200px) {
  .showcase-card {
    min-height: fit-content;
  }
  .img-fluid.showcase-card-icon {
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 992px) {
  .blog-title-container {
    padding: 0 !important;
  }
  .blog-page-content.blog-title-container {
    padding: 70px 15px 0px !important;
  }
  .about-us-container {
    padding: 0px !important;
  }
  #navbar-sm {
    background: rgb(248, 249, 250);
  }
  #navbar-sm a,
  .nav-logo .logo-content {
    color: var(--black) !important;
  }
  .about-best-solar {
    padding-top: 0;
  }
  .navbar.navbar-expand-lg {
    position: static;
  }
  .col-md-8.showcase-content {
    margin-top: 0px;
  }
  .who-we-are {
    margin-top: 0;
  }
  .solar-panels-img-container {
    margin-top: -5px;
  }
}

@media (min-width: 768px) {
  .blog-card-wrapper .card .card-body {
    padding: 2.8rem 3rem 2.8rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .formContainer {
    align-self: center;
    width: 100%;
  }
  .footer-btn {
    font-size: 14px;
  }
}

@media (max-width: 769px) {
  .solar-flex-container {
    flex-direction: column !important;
  }
  .steps-flex-container {
    flex-direction: column;
  }
  .steps-flex-container > div {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .w-60 {
    width: 70%;
  }
}

@media (max-width: 992px) {
  .w-60 {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .showcase-card {
    min-height: auto;
    flex-direction: column;
  }
  .showcase-card-icon {
    width: 50px;
  }
  .flex-item {
    width: 90%;
    height: 360px;
    align-self: center;
    margin-bottom: 10px;
  }
  .steps-to-energy .fixed-width {
    max-width: 585px;
  }
  .blog-card-wrapper .card .card-body {
    padding: 40px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .who-we-are {
    margin-top: 0;
  }
  /*.w-60{*/
  /*width: 100%;*/
  /*}*/
  .panel-type-img {
    width: 100%;
  }
  .solar-page-info {
    border-right: 0;
  }
  .footer-col-sm-width {
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  .w-60 {
    width: 100%;
  }
}
